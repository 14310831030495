#printText {
  ol li {
    margin: 0 0 1rem 1rem;
    padding: 0;
  }
}

.disabled {
  pointer-events: none;
}
