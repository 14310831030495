<script lang="ts">
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { mixins } from 'vue-class-component';
import Upload from '@/components/Upload.vue';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Auth } from '@/store/modules/auth.module';
import * as _ from 'lodash';
import attachment from '@/components/attachment/attachment.vue';

import AccommodationSchema from '@/modules/accommodation/schema/accommodation.schema';

import { AccommodationDto } from '../../../app/entities/tenant/accommodation.dto';

import CustomFormModal from '@/components/customFormModal/CustomFormModal.vue';

import CustomiseFormQuestions from '@/modules/accommodation/schema/customise.form.schema';

interface FormulateForm {
  formSubmitted(): boolean;
}

export interface CustomQuestions {
  sectionTitle: string;
  sectionDescription: string;
  questions: QuestionsEntity[];
}
export interface QuestionsEntity {
  question: string;
  name: string;
  id: number;
  required?: boolean | null;
}

@Component({
  components: {
    Upload,
    CustomFormModal,
    attachment,
  },
})
export default class CustomiseForm extends mixins(AccommodationSchema) {
  @Ref('form') readonly form: any;

  @Ref('detailsForm') readonly detailsForm: FormulateForm;

  @Ref('healthDisclaimerForm') readonly healthDisclaimerForm: FormulateForm;

  @Ref('documentForm') documentForm: any;

  @PropSync('active') activeSync!: boolean;

  @PropSync('selectedCustomQuestions') syncedSelectedCustomQuestions!: any;

  @PropSync('accommodation') syncedAccommodation!: any;

  @PropSync('currentStep') syncedCurrentStep!: number;

  @Prop({ default: () => null }) accommodationEdit: any;

  $refs!: any;

  baseUrl = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/upload/`;

  url = `${process.env.VUE_APP_API_URL}/v1/api/accommodation/logo/upload`;

  logo = [];

  singleDocument: any = {
    attachEmail: false,
  };

  isLoading = false;

  _ = _;

  formPopupActive = false;

  questions: Record<string, any>[] = [];

  customQuestions: CustomQuestions[] = [];

  questionIds: number[] = [];

  disabledQuestions: string[] = [];

  editedAccomodation: AccommodationDto;

  @Watch('currentStep')
  checkStep(newStep: number, oldStep: number) {
    /** i keep a copy of the filledin accom form on submit
    and use that to fill if they go back a step */
    if (newStep == 0 && newStep < oldStep) {
      this.$nextTick(() => {
        this.syncedAccommodation = this.editedAccomodation;
        /*         this.detailsSchema[0].children[0].value.url = '/path/to/document.pdf';
         */
      });
    }
  }

  created() {
    this.customQuestions = CustomiseFormQuestions;
    if (this.syncedAccommodation.guestConfirmation.confirmationSchema !== undefined) {
      this.syncedAccommodation.guestConfirmation.confirmationSchema.find((element: any) => {
        this.syncedSelectedCustomQuestions.push(element);
      });
    }
    this.customQuestions.forEach((section) => {
      section.questions.find((question: any) => {
        if (question.required) this.disabledQuestions.push(question.name);
      });
      section.questions.forEach((question: any) => {
        if (
          this.syncedAccommodation.guestConfirmation.confirmationSchema !== undefined &&
          this.syncedAccommodation.guestConfirmation.confirmationSchema.includes(question.name)
        ) {
          return;
        } else this.questionIds.push(question.id);
      });
    });
  }

  openForm() {
    this.$refs.customFormModal.removeOptionFromForm(this.syncedSelectedCustomQuestions);
    this.formPopupActive = true;
  }

  hasQuestion(id: number) {
    return this.questionIds.some((key: number) => key === id);
  }

  addRemove(id: number, name: string) {
    const index = this.questionIds.findIndex((key) => key === id);
    if (index >= 0) {
      this.questionIds = this.questionIds.filter((ele) => ele !== id);
      this.syncedSelectedCustomQuestions.push(name);
    } else {
      this.questionIds.push(id);
      this.syncedSelectedCustomQuestions.find((questionName: string) => {
        if (questionName === name) {
          this.syncedSelectedCustomQuestions.splice(this.syncedSelectedCustomQuestions.indexOf(questionName), 1);
        }
      });
    }
  }

  changeStep(incr: string) {
    window.scrollTo(0, 0);
    if (incr === 'incr') {
      this.syncedCurrentStep += 1;
    } else if (incr === 'dcr') {
      this.syncedCurrentStep -= 1;
    } else {
      return;
    }
  }

  get headers() {
    return {
      Authorization: `Bearer ${Auth?.token}`,
      'x-organisation-id': `${tenantModule.apiKey}`,
    };
  }

  validate(form: FormulateForm, args: string): boolean {
    return form?.formSubmitted() && args !== '';
  }

  // submitHandler(data: any): any {
  //   this.editedAccomodation = data;
  //   this.detailsSchema[0].children[1].value = data.file;
  //   this.syncedAccommodation = data;
  //   this.syncedAccommodation.healthDisclaimer = data;
  //   this.logo = data.file;
  //   this.syncedCurrentStep += 1;
  // }

  getFormOptions(options: Record<string, any>[]) {
    if (options) return _.omit(_.omit(options, 'optionGroupId'), 'optionGroupId');
  }
}
</script>

<template>
  <!-- *** Cutomise check-in form *** -->

  <div class="bg-white dark:bg-gray-700 dark:text-white px-4 py-5 sm:p-6 border border-gray-300 rounded-lg mt-2">
    <CustomFormModal ref="customFormModal" :form-popup-active.sync="formPopupActive">
      <template #header>
        <h2 class="text-gray-600 text-center mb-8 dark:text-white">Guest Pre-Check-In Form</h2>
      </template>
    </CustomFormModal>
    <FormulateForm>
      <!-- *** Required information *** -->
      <div v-for="(section, ind) in customQuestions" :key="ind" class="mb-10 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-white">{{ section.sectionTitle }}</h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-gray-200"> {{ section.sectionDescription }} </p>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2 w-full">
          <div class="grid grid-cols-1">
            <div class="relative shadow-lg px-5 py-2 rounded-lg">
              <div v-for="question in section.questions" :key="question.name" class="flex flex-shrink justify-start gap-4">
                <FormulateInput type="text" :label="question.question" min="0" max="5" show-value class="p-2 questions" /><div
                  class="flex flex-grow justify-end p-2 w-1/4"
                >
                  <!--  <FormulateInput
                            :type="item.type.name"
                            :options="item.options.option"
                            :label="item.label"
                            min="0"
                            max="5"
                            show-value
                            class="p-2"
                            disabled="disabled"
                          /><div class="flex flex-grow justify-end w-full p-2"> -->
                  <button
                    type="button"
                    class="
                      inline-flex
                      flex-shrink-0
                      h-6
                      w-12
                      border-2 border-transparent
                      rounded-full
                      cursor-pointer
                      transition-colors
                      ease-in-out
                      duration-200
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                    "
                    aria-pressed="false"
                    :class="[hasQuestion(question.id) ? 'bg-red-600' : 'bg-gray-200']"
                    :disabled="disabledQuestions.includes(question.name)"
                    @click="addRemove(question.id, question.name)"
                  >
                    <span class="sr-only">Use setting</span>
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span
                      :class="hasQuestion(question.id) ? 'translate-x-6' : 'translate-x-0'"
                      class="
                        pointer-events-none
                        relative
                        inline-block
                        h-5
                        w-5
                        rounded-full
                        bg-white
                        shadow
                        transform
                        ring-0
                        transition
                        ease-in-out
                        duration-200
                      "
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span
                        :class="hasQuestion(question.id) ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200'"
                        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        aria-hidden="true"
                      >
                        <svg class="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span
                        :class="hasQuestion(question.id) ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100'"
                        class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                        aria-hidden="true"
                      >
                        <svg class="bg-white h-3 w-3 text-red-600" :class="'disabled ? text-black'" fill="currentColor" viewBox="0 0 12 12">
                          <path
                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex md:flex-row flex-col md:justify-end relative">
        <FormulateInput
          type="button"
          :disabled="isLoading"
          :label="isLoading ? 'Loading...' : 'Preview Form'"
          input-class="bg-teal-500
                                py-2
                                px-12
                                
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-white
                                hover:bg-teal-600
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                                  "
          @click="openForm" />

        <FormulateInput
          type="button"
          :disabled="isLoading"
          :label="isLoading ? 'Loading...' : 'Previous'"
          input-class="bg-white
                                py-2
                                px-12
                                ml-2
                                border border-gray-300
                                rounded-md
                                shadow-sm
                                text-sm
                                font-medium
                                text-gray-700
                                hover:bg-gray-50
                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                                  "
          @click="changeStep('dcr')" />

        <FormulateInput
          type="submit"
          :disabled="isLoading"
          :label="isLoading ? 'Loading...' : 'Next'"
          input-class="bg-red-600
                              py-2
                              ml-2
                              px-12
                              border border-red-600
                              rounded-md
                              shadow-sm
                              text-sm
                              font-medium
                              text-white
                              hover:bg-red-700
                              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click.prevent="changeStep('incr')"
      /></div>
    </FormulateForm>
    <!-- <FormulateForm ref="healthDisclaimerForm" name="f-2" :schema="FormScheme" @submit="submitHandler"> </FormulateForm> -->
  </div>
</template>

<style scoped>
button:disabled {
  background-color: rgb(182, 182, 182) !important;
}
</style>
