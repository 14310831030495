








































































































































.file-preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  background: #f5f5f5;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}
.theme-list .vue-file-agent .file-preview-wrapper .file-preview {
  &::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 53px;
    height: 100%;
    background: #66999b !important;
    border-color: #66999b;
  }
}
.file-name-edit-icon {
  color: white !important;
}
.file-name-text {
  color: white !important;
}
.file-ext {
  color: white !important;
}
.file-size {
  display: none !important;
}

.file-delete {
  color: white !important;
}
