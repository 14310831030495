
































































































































































.tinyMC {
  height: 500px;
}
.tab-container {
  min-height: 600px;
}

.form-input:focus {
  border-color: #008489 !important;
  outline: none;
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
}
